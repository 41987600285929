import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { MonthShortResource, NameValueResource, PackageListResource, PackageShortResource, PackageStoreData } from '@/store/types'
import { formatDate } from '@/utils/functions'

@Component
export default class CourseGroupMonthMixin extends Vue {
  protected innerPackages: Array<PackageShortResource & PackageListResource> = []

  protected getPackageName (formPackage: PackageStoreData) {
    const pack = this.innerPackages.find(pack => pack.id === formPackage.packageId)
    return pack
      ? `${pack.title}. ${pack.package ? pack.package.title : ''} ${pack.package ? pack.package.course.type === 'special' ? 'Спецкурс' : pack.package.groupType.name : pack.course.type === 'special' ? 'Спецкурс' : pack.groupType.name}. ${this.getMonthsRangeByNameValue(pack.package ? pack.package.months.map((item: NameValueResource) => +item.value) : pack.months.map((item: NameValueResource) => +item.value), pack.package ? pack.package.months : pack.months)}`
      : 'Без названия'
  }

  protected getMonthByID (id: number, months: MonthShortResource[]) {
    const month = months.find((month: MonthShortResource) => month.id === id)
    return month ? formatDate(month.name, 'LLLL') : ''
  }

  protected getMonthByValue (value: number, months: NameValueResource[]) {
    const month = months.find((month: NameValueResource) => month.value === value)
    return month ? formatDate(month.name, 'LLLL') : ''
  }

  protected getMonthsRange (months: NameValueResource[]) {
    const first = this.getMonthByValue(+months[0].value, months)
    const last = this.getMonthByValue(+months[months.length - 1].value, months)
    return months.length === 1 ? first : `${first} - ${last}`
  }

  protected getMonthsRangeByIds (ids: number[], months: MonthShortResource[]) {
    const first = this.getMonthByID(ids[0], months)
    const last = this.getMonthByID(ids[ids.length - 1], months)
    return ids.length === 1 ? first : `${first} - ${last}`
  }

  protected getMonthsRangeByNameValue (ids: number[], months: NameValueResource[]) {
    const first = this.getMonthByValue(ids[0], months)
    const last = this.getMonthByValue(ids[ids.length - 1], months)
    return ids.length === 1 ? first : `${first} - ${last}`
  }
}
