
























































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { reduce } from 'lodash'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Tag from '@/components/_uikit/Tag.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import UserCard from '@/components/cards/UserCard.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerOrdersModule from '@/store/modules/manager/orders'
// interfaces
import {
  CourseType,
  ManagerOrderLargeResource,
  ManagerOrderStoreRequest,
  NameValueResource,
  OrderStatus,
  PackageShortResource,
  PurchaseGroupResource,
  SelfResource,
} from '@/store/types'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    SwitchInput,
    Tag,
    UserCard,
    UserSocialCard,
  },
})
export default class OrderInfoModal extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private orderId!: number

  private localVisible = this.visible

  private form: ManagerOrderStoreRequest = {
    isManual: false,
    managerId: null,
    packages: [],
    userId: 0,
  }
  private order: ManagerOrderLargeResource|null = null
  private packages: PackageShortResource[] = []
  private chosenOrderStatus: NameValueResource | null = null
  // orders possible special case
  private isOrderPaid = false
  private isWaitingForPayment = false
  private isRefundOrCancelled = false
  private special = CourseType.SPECIAL

  private isMy = false
  private linkBaseUrl = `${process.env.VUE_APP_BACKEND_ENDPOINT}/payment/`
  private linkOrderSourceDiscount = `${process.env.VUE_APP_BACKEND_ENDPOINT}/manager/orders/`

  private get isSpecial() :boolean {
    return Boolean(this.order?.groups[0].course.type === CourseType.SPECIAL)
  }

  private get orderStatuses() {
    return DictionaryModule.orderStatuses
  }

  private get orderStatusesPaidCase() {
    return DictionaryModule.orderStatuses.filter(item => item.value === OrderStatus.PAID || item.value === OrderStatus.REFUND)
  }

  private get orderStatusesNotPaidCase() {
    return DictionaryModule.orderStatuses.filter(item => item.value === OrderStatus.WAIT || item.value === OrderStatus.PAID || item.value === OrderStatus.CANCELED)
  }

  private get self () {
    return AuthModule.self as SelfResource
  }

  private get isPackageTagLarge() {
    return this.$vuetify.breakpoint.name !== 'xs'
  }

  // Сумма стоимости заказа без скидок, промокодов и пакетных предложений (суммы месяцев заказа)
  private get orderOriginalPriceSum() {
    if (!this.order)
      return 0
    return this.order.packages.reduce((acc: number, cur: PackageShortResource) => acc + (cur.package ? cur.package.originalPrice : cur.originalPrice), 0)
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private syncForm (response: ManagerOrderLargeResource) {
    this.order = response

    this.packages = reduce(this.order.groups, (packages: PackageShortResource[], item: PurchaseGroupResource) => {
      if (item.packages) {
        packages.push(...item.packages.map(pack => ({ ...pack, title: item.course.name })))
      }
      return packages
    }, [])

    this.form = {
      isManual: false,
      managerId: this.order.manager ? this.order.manager.id : null,
      packages: this.packages.map((item: PackageShortResource) => ({
        packageId: item.id,
        price: item.price,
      })),
      status: this.order.status.value as OrderStatus,
      userId: this.order.user.id,
    }

    this.isMy = !!(this.order.manager && this.order.manager.id === this.self.id)
  }

  private fetchOrder() {
    return ManagerOrdersModule.fetchOrder(this.orderId)
      .then(this.syncForm)
      .catch(this.notifyError)
  }

  private handleIsMyChange (value: boolean) {
    this.form.managerId = value ? this.self.id : null
  }

  private handleChosenOrderStatus(orderStatus: NameValueResource) {
    this.chosenOrderStatus = orderStatus
    this.form.status = orderStatus.value as OrderStatus
  }

  private handleClose() {
    this.localVisible = false
    this.$emit('closeModal')
  }

  private handleSaveOrderStatus() {
    if (this.order) {
      ManagerOrdersModule.saveOrder({ orderID: this.order.id, params: this.form })
        .then((response: ManagerOrderLargeResource) => {
          this.syncForm(response)
          this.notifySuccess(`Заказ # ${this.order?.id} обновлён`)
          this.$emit('update-order', { orderId: this.order?.id, orderStatus: this.chosenOrderStatus || response.status })
        })
        .catch(this.notifyError)
    }
  }

  private determinateOrderStatus() {
    switch((this.order as ManagerOrderLargeResource).status.value) {
    case OrderStatus.PAID:
      this.isOrderPaid = true
      break
    case OrderStatus.WAIT:
      this.isWaitingForPayment = true
      break
    case OrderStatus.REFUND:
    case OrderStatus.CANCELED:
      this.isRefundOrCancelled = true
      break
    }
  }

  private resetOrderStatus() {
    this.order = null
    this.isOrderPaid = false
    this.isWaitingForPayment = false
    this.isRefundOrCancelled = false
  }

  @Watch('visible')
  watchVisibleState(value: boolean) {
    this.localVisible = value
    if (value) {
      this.fetchOrder()
        .then(this.determinateOrderStatus)
    } else {
      this.resetOrderStatus()
    }
  }
}
