


















import { MetaInfo } from 'vue-meta'
import { Component } from 'vue-property-decorator'

import OrdersTable from '@/components/tables/OrdersTable.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import { openNewTab } from '@/utils/functions'
import ManagerOrdersModule from '@/store/modules/manager/orders'

enum MenuActions {
  CREATE = 'create',
  EXPORT = 'export',
}

@Component({
  components: {
    OrdersTable,
    Parameters,
  },
})
export default class OrdersList extends PermissionsMixin {
  private get menu () {
    return this.hasPermission(this.Permission.ORDERS_CREATE)
      ? [
        { name: 'Новый заказ', value: MenuActions.CREATE },
        { name: 'Выгрузить в excel', value: MenuActions.EXPORT },
      ]
      : [{ name: 'Выгрузить в excel', value: MenuActions.EXPORT }]
  }

  private get filter () {
    return ManagerOrdersModule.ordersFilter
  }

  // Note: Менеджер, который видит по-умолчанию только свои заказы, но при этом может искать среди всех заказов
  private get isManager () {
    return !this.hasPermission(this.Permission.ORDERS_VIEW) && this.hasPermission(this.Permission.ORDERS_APPOINT_SELF)
  }

  private handleExport () {
    openNewTab('/manager/orders/export', this.filter)
  }

  private handleMenuItemClick (action: MenuActions) {
    switch(action) {
    case MenuActions.CREATE:
      this.$router.push({ name: 'manager.commerce.orders.new' })
      break
    case MenuActions.EXPORT:
      this.handleExport()
      break
    }
  }
  // .
  private metaInfo (): MetaInfo {
    return {
      title: 'Заказы',
    }
  }
}
