import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  AssignToCourseRequest,
  CourseCloneData,
  CourseLargeResource,
  CourseLinkResourcePaginator,
  CourseProgramRequest,
  CourseShortResourcePaginator,
  CourseStoreData,
  ExerciseAccessLevel,
  ExerciseLargeResource,
  ExerciseShortResource,
  ExerciseStore,
  FullProgramResource,
  GroupLargeResource,
  GroupPackagesData,
  GroupPackagesResource,
  GroupStoreData,
  GroupType,
  IManagerCoursesFilter,
  IManagerCoursesLinksFilter,
  ISearchPackagesFilter,
  LessonShortResource,
  LessonStore,
  ManagerCoursesGetRequest,
  ManagerCoursesIdClonePostRequest,
  ManagerCoursesIdDeleteRequest,
  ManagerCoursesIdGetRequest,
  ManagerCoursesIdGroupGroupTypeDeleteRequest,
  ManagerCoursesIdGroupGroupTypeGetRequest,
  ManagerCoursesIdGroupGroupTypePackagesGetRequest,
  ManagerCoursesIdGroupGroupTypePackagesPostRequest,
  ManagerCoursesIdGroupGroupTypePostRequest,
  ManagerCoursesIdPostRequest,
  ManagerCoursesIdProgramGetRequest,
  ManagerCoursesIdProgramMonthIdExercisesExerciseUuidDeleteRequest,
  ManagerCoursesIdProgramMonthIdExercisesExerciseUuidGetRequest,
  ManagerCoursesIdProgramMonthIdExercisesExerciseUuidPostRequest,
  ManagerCoursesIdProgramMonthIdExercisesGetRequest,
  ManagerCoursesIdProgramMonthIdExercisesPostRequest,
  ManagerCoursesIdProgramMonthIdGetRequest,
  ManagerCoursesIdProgramMonthIdLessonsGetRequest,
  ManagerCoursesIdProgramMonthIdLessonsLessonIdDeleteRequest,
  ManagerCoursesIdProgramMonthIdLessonsLessonIdGetRequest,
  ManagerCoursesIdProgramMonthIdLessonsLessonIdPostRequest,
  ManagerCoursesIdProgramMonthIdLessonsPostRequest,
  ManagerCoursesIdProgramPostRequest,
  ManagerCoursesIdPublishPostRequest,
  ManagerCoursesIdReviewAssignPatchRequest, ManagerCoursesIdReviewGetRequest,
  ManagerCoursesIdUnpublishPostRequest,
  ManagerCoursesLinksGetRequest,
  ManagerCoursesPackagesSearchGetRequest,
  ManagerCoursesPostRequest,
  ManagerReviewsGetParams,
  ManagerReviewsGetRequest,
  ManagerTeachersShortGetRequest,
  ProgramMonthLargeResource,
  TeacherShortResource,
  ManagerCoursesIdRecommendationsGetRequest,
  ManagerCoursesIdRecommendationsPostRequest,
  SetRecommendationsRequest,
  PackageListResource,
} from '@/store/types'
import { getStorage, setStorage, tableOptionsToParams } from '@/utils/functions'

/**
 * Работа со словарями системы
 */

@Module({
  dynamic: true,
  name: 'ManagerCourses',
  namespaced: true,
  store,
})
class ManagerCourses extends VuexModule {
  // ---------------------------- Courses ---------------------------- >>
  // Filter
  coursesFilter: IManagerCoursesFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setCoursesFilter (payload: IManagerCoursesFilter) {
    this.coursesFilter = Object.assign({}, payload)
  }

  // Entities
  courses: CourseShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setCourses (payload: CourseShortResourcePaginator) {
    this.courses = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchCourses () {
    const { data } = await ManagerCoursesGetRequest(tableOptionsToParams(this.coursesFilter))
    this.setCourses(data)
    return data
  }

  @Action({ rawError: true })
  async searchCourses (filter: IManagerCoursesFilter) {
    const { data } = await ManagerCoursesGetRequest(tableOptionsToParams(filter))
    return data
  }

  // @Action({ rawError: true })
  // async searchCoursesTree (payload: ManagerCoursesTreeGetParams) {
  //   const { data } = await ManagerCoursesTreeGetRequest(
  //     payload,
  //     { loading: false },
  //   )
  //   return data
  // }

  // @Action({ rawError: true })
  // async searchCoursesTreeWithParams (payload: ManagerCoursesTreeGetParams) {
  //   const { data } = await ManagerCoursesTreeGetRequest(payload)
  //   return data
  // }

  // ---------------------------- Courses links ---------------------------- >>
  // Filter
  // Todo: поменять на правильный фильтр
  coursesLinksFilter: IManagerCoursesLinksFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setCoursesLinksFilter (payload: IManagerCoursesLinksFilter) {
    this.coursesLinksFilter = Object.assign({}, payload)
  }

  // Entities
  coursesLinks: CourseLinkResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setCoursesLinks (payload: CourseLinkResourcePaginator) {
    this.coursesLinks = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchCoursesLinks () {
    const { data } = await ManagerCoursesLinksGetRequest(tableOptionsToParams(this.coursesLinksFilter))
    this.setCoursesLinks(data)
    return data
  }

  // ---------------------------- Course ---------------------------- >>

  course: CourseLargeResource | null = null

  @Mutation
  setCourse (payload: CourseLargeResource) {
    this.course = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchCourse (courseID: number) {
    const { data } = await ManagerCoursesIdGetRequest(courseID)
    this.setCourse(data)
    return data
  }

  @Action({ rawError: true })
  async saveCourse (payload: { courseID?: number, params: CourseStoreData }) {
    if (payload.courseID) {
      const { data } = await ManagerCoursesIdPostRequest(payload.courseID, payload.params)
      this.setCourse(data)
      return data
    } else {
      const { data } = await ManagerCoursesPostRequest(payload.params)
      this.setCourse(data)
      return data
    }
  }

  @Action({ rawError: true })
  async copyCourse (payload: { courseID: number, params: CourseCloneData }) {
    const { data } = await ManagerCoursesIdClonePostRequest(payload.courseID, payload.params)
    return data
  }

  @Action({ rawError: true })
  async deleteCourse (courseID: number) {
    const { data } = await ManagerCoursesIdDeleteRequest(courseID)
    return data
  }

  @Action({ rawError: true })
  async toggleCourse (payload: { courseID: number, publish: boolean }) {
    if (payload.publish) {
      const { data } = await ManagerCoursesIdPublishPostRequest(payload.courseID)
      this.setCourse(data)
      return data
    } else {
      const { data } = await ManagerCoursesIdUnpublishPostRequest(payload.courseID)
      this.setCourse(data)
      return data
    }
  }

  // ---------------------------- Course group ---------------------------- >>

  group: GroupLargeResource | null = null

  @Mutation
  setGroup (payload: GroupLargeResource) {
    this.group = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchGroup (payload: { courseID: number, type: GroupType }) {
    const { data } = await ManagerCoursesIdGroupGroupTypeGetRequest(payload.courseID, payload.type)
    this.setGroup(data)
    return data
  }

  @Action({ rawError: true })
  async saveGroup (payload: { courseID: number, type: GroupType, params?: GroupStoreData }) {
    const { data } = await ManagerCoursesIdGroupGroupTypePostRequest(payload.courseID, payload.type, payload.params)
    this.setGroup(data)
    return data
  }

  @Action({ rawError: true })
  async deleteGroup (payload: { courseID: number, type: GroupType, params?: GroupStoreData }) {
    const { data } = await ManagerCoursesIdGroupGroupTypeDeleteRequest(payload.courseID, payload.type)
    return data
  }

  // ---------------------------- Course group packages ---------------------------- >>

  packages: GroupPackagesResource | null = null
  searchedPackages: PackageListResource[] = []

  @Mutation
  setPackages (payload: GroupPackagesResource) {
    this.packages = Object.assign({}, payload)
  }

  @Mutation
  setSearchedPackages (payload: PackageListResource[]) {
    this.searchedPackages = Object.assign({}, payload)
  }

  @Mutation
  clearPackages () {
    this.packages = null
  }

  @Action({ rawError: true })
  async fetchPackages (payload: { courseID: number, type: GroupType }) {
    const { data } = await ManagerCoursesIdGroupGroupTypePackagesGetRequest(payload.courseID, payload.type)
    this.setPackages(data)
    return data
  }

  @Action({ rawError: true })
  async savePackages (payload: { courseID: number, type: GroupType, params: GroupPackagesData }) {
    const { data } = await ManagerCoursesIdGroupGroupTypePackagesPostRequest(payload.courseID, payload.type, payload.params)
    this.setPackages(data)
    return data
  }

  @Action({ rawError: true })
  async searchPackages (payload: ISearchPackagesFilter) {
    const { data } = await ManagerCoursesPackagesSearchGetRequest(tableOptionsToParams(payload))
    this.setSearchedPackages(data.data)

    return data
  }

  // ---------------------------- Course program ---------------------------- >>
  // Program
  program: FullProgramResource | null = null

  @Mutation
  setProgram (payload: FullProgramResource) {
    this.program = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchProgram (courseID: number) {
    const { data } = await ManagerCoursesIdProgramGetRequest(courseID)
    this.setProgram(data)
    return data
  }

  @Action({ rawError: true })
  async saveProgram(payload: { body: CourseProgramRequest, courseID: number }) {
    const { data } = await ManagerCoursesIdProgramPostRequest(payload.courseID, payload.body)
    this.setProgram(data)
    return data
  }

  // Month
  programMonth: ProgramMonthLargeResource | null = null

  @Mutation
  setProgramMonth (payload: ProgramMonthLargeResource) {
    this.programMonth = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchProgramMonth (payload: { courseID: number, monthID: number }) {
    const { data } = await ManagerCoursesIdProgramMonthIdGetRequest(payload.courseID, payload.monthID)
    this.setProgramMonth(data)
    return data
  }

  // ---------------------------- Course lessons ---------------------------- >>
  // List
  lessons: LessonShortResource[] = []

  @Mutation
  setLessons (payload: LessonShortResource[]) {
    this.lessons = [...payload]
  }

  @Action({ rawError: true })
  async fetchLessons (payload: { courseID: number, monthID: number }) {
    const { data } = await ManagerCoursesIdProgramMonthIdLessonsGetRequest(payload.courseID, payload.monthID)
    this.setLessons(data)
    return data
  }

  // Item
  // lesson: any = null

  // @Mutation
  // setLesson (payload: LessonShortResource) {
  //   this.lesson = Object.assign({}, payload)
  // }

  @Action({ rawError: true })
  async fetchLesson (payload: { courseID: number, monthID: number, lessonID: number }) {
    const { data } = await ManagerCoursesIdProgramMonthIdLessonsLessonIdGetRequest(payload.courseID, payload.monthID, payload.lessonID)
    // this.setLesson(data)
    return data
  }

  @Action({ rawError: true })
  async saveLesson (payload: { courseID: number, monthID: number, lessonID?: number, params: LessonStore }) {
    if (payload.lessonID) {
      const { data } = await ManagerCoursesIdProgramMonthIdLessonsLessonIdPostRequest(payload.courseID, payload.monthID, payload.lessonID, payload.params)
      // this.setLesson(data)
      return data
    } else {
      const { data } = await ManagerCoursesIdProgramMonthIdLessonsPostRequest(payload.courseID, payload.monthID, payload.params)
      // this.setLesson(data)
      return data
    }
  }

  @Action({ rawError: true })
  async deleteLesson (payload: { courseID: number, monthID: number, lessonID: number }) {
    const { data } = await ManagerCoursesIdProgramMonthIdLessonsLessonIdDeleteRequest(payload.courseID, payload.monthID, payload.lessonID)
    return data
  }

  // ---------------------------- Course exercises ---------------------------- >>
  // List
  exercises: ExerciseShortResource[] = []
  exercise: ExerciseLargeResource | null = null

  @Mutation
  setExercises (payload: ExerciseShortResource[]) {
    this.exercises = [...payload]
  }

  @Mutation
  setExercise(payload: ExerciseLargeResource | null) {
    this.exercise = payload
  }

  @Action({ rawError: true })
  async fetchExercises (payload: { accessLevel: ExerciseAccessLevel, courseID: number, monthID: number }) {
    const { data } = await ManagerCoursesIdProgramMonthIdExercisesGetRequest(payload.courseID, payload.monthID, {
      accessLevel: payload.accessLevel,
    })
    this.setExercises(data)
    return data
  }

  // Item
  // exercise: any = null
  //
  // @Mutation
  // setExercise (payload: ExerciseLargeResource) {
  //   this.exercise = Object.assign({}, payload)
  // }

  @Action({ rawError: true })
  async fetchExercise (payload: { courseID: number, monthID: number, exerciseUUID: string }) {
    const { data } = await ManagerCoursesIdProgramMonthIdExercisesExerciseUuidGetRequest(payload.courseID, payload.monthID, payload.exerciseUUID)
    this.setExercise(data)
    return data
  }

  @Action({ rawError: true })
  async saveExercise (payload: { courseID: number, monthID: number, exerciseUUID?: string, params: ExerciseStore }) {
    if (payload.exerciseUUID) {
      const { data } = await ManagerCoursesIdProgramMonthIdExercisesExerciseUuidPostRequest(payload.courseID, payload.monthID, payload.exerciseUUID, payload.params)
      this.setExercise(data)
      return data
    } else {
      const { data } = await ManagerCoursesIdProgramMonthIdExercisesPostRequest(payload.courseID, payload.monthID, payload.params)
      this.setExercise(data)
      return data
    }
  }

  @Action({ rawError: true })
  async deleteExercise (payload: { courseID: number, monthID: number, exerciseUUID: string }) {
    const { data } = await ManagerCoursesIdProgramMonthIdExercisesExerciseUuidDeleteRequest(payload.courseID, payload.monthID, payload.exerciseUUID)
    this.setExercise(null)
    return data
  }

  // ---------------------------- Course teachers ---------------------------- >>
  teachersList: TeacherShortResource[] = getStorage('teachers') || []

  @Mutation
  setTeachersList(payload: TeacherShortResource[]) {
    this.teachersList = payload
  }

  @Action({ rawError: true })
  async fetchTeachers() {
    const { data } = await ManagerTeachersShortGetRequest()
    setStorage('teachers', data, 365)
    this.setTeachersList(data)
    return data
  }

  // ---------------------------- Reviews ---------------------------- >>
  @Action({ rawError: true })
  async fetchReviews(payload?: ManagerReviewsGetParams) {
    const { data } = await ManagerReviewsGetRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async fetchReviewsCourse(courseID: number) {
    const { data } = await ManagerCoursesIdReviewGetRequest(courseID)
    return data
  }

  @Action({ rawError: true })
  async saveReviewsCourse(payload: { courseID: number, body: AssignToCourseRequest }) {
    const { data } = await ManagerCoursesIdReviewAssignPatchRequest(payload.courseID, payload.body)
    return data
  }

  // ---------------------------- Course recomendations ---------------------------- >>
  @Action({ rawError: true })
  async fetchRecommendedCourses(courseId: number, options?: any) {
    const { data } = await ManagerCoursesIdRecommendationsGetRequest(courseId, options)
    return data
  }

  @Action({ rawError: true })
  async setRecommendedCourses(payload: { courseID: number, body: SetRecommendationsRequest, options?: any }) {
    const { data } = await ManagerCoursesIdRecommendationsPostRequest(payload.courseID, payload.body, payload.options)
    return data
  }
}

const ManagerCoursesModule = getModule(ManagerCourses)

export default ManagerCoursesModule
